<template>
	<div class="container">
		<top-header></top-header>
		<div class="design">
			<div class="width-1300">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>产品智能辅助设计</el-breadcrumb-item>
				</el-breadcrumb>
				<div class="title">
					<p>产品智能辅助设计</p>
					<p>Product Intelligent Aided Design</p>
				</div>
				<div class="item">
					<img src="../../../static/images/icon/img11.png" @click="gotoUrl(1)">
				</div>
				<div class="item">
					<img src="../../../static/images/icon/img10.png">
				</div>
				<div class="item">
					<img src="../../../static/images/icon/img12.png" @click="gotoUrl(3)">
				</div>
				<div class="item">
					<img src="../../../static/images/icon/img13.png" @click="gotoUrl(4)">
				</div>
			</div>
		</div>
		<!-- <div class="design">
			<div class="advantage">
				<div class="advantage-list" :class="active == index ? 'advantage-active' : ''"
					v-for="(item,index) in advantage" :key="index" @mousemove="openSwitch(index)">
					<div class="cover_box">
						<img :src="require('../../../static/images/'+item.img1)">
						<div class="cover_box_txt">
							<h3>{{item.text}}</h3>
							<h4>{{item.title}}</h4>
						</div>
					</div>
					<div class="innner_box" @click="gotoUrl('/operation')">
						<img :src="require('../../../static/images/'+item.img2)">
						<div class="innner_box_txt">
							<div class="innner_box_txt_title">
								<h3>{{item.text}}</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="design-item">
				<img src="../../../static/images/img2.png" alt="">
				<h2 class="Fromenlarge">
					<p>磨机衬板辅助设计模块</p>
				</h2>
				<div class="dx-more">
					<div class="dx-more-img" @click="gotoUrl('/operation')">
						<img src="../../../static/images/more_img.png" alt="" class="FromUpanddown">
					</div>
				</div>
			</div>
			<div class="design-item">
				<img src="../../../static/images/img2.png" alt="">
				<h2 class="Fromenlarge">
					<p>渣浆泵辅助设计模块</p>
				</h2>
				<div class="dx-more">
					<div class="dx-more-img" @click="gotoUrl('/operation')">
						<img src="../../../static/images/more_img.png" alt="" class="FromUpanddown">
					</div>
				</div>
			</div>
			<div class="design-item">
				<img src="../../../static/images/img2.png" alt="">
				<h2 class="Fromenlarge">
					<p>振动筛辅助设计模块</p>
				</h2>
				<div class="dx-more">
					<div class="dx-more-img" @click="gotoUrl('/operation')">
						<img src="../../../static/images/more_img.png" alt="" class="FromUpanddown">
					</div>
				</div>
			</div>
			<div class="design-item">
				<img src="../../../static/images/img2.png" alt="">
				<h2 class="Fromenlarge">
					<p>圆筒筛辅助设计模块</p>
				</h2>
			</div>
		</div> -->
		<bottom-footer></bottom-footer>
	</div>
</template>

<script>
	import topHeader from '../../components/header.vue'
	import bottomFooter from '../../components/footer.vue'
	export default {
		components: {
			topHeader,
			bottomFooter
		},
		data() {
			return {
				
			}
		},
		created() {

		},
		methods: {
			gotoUrl($id){
			    if($id == 1){
                    this.$router.push({
                        query:{
                            id:$id
                        },
                        name:'designinfo'
                    })
			    }else{
			        if($id == 3){
			            window.open('http://www.naipu.cloud/monitor8505/')
			        }else if($id == 4){
			            window.open('http://www.naipu.cloud/monitor8503/')
			        }
			    }
			},
		}
	}
</script>

<style lang="scss">
	.design {
		margin-top: 60px;
		padding-top: 20px;
		min-height: 87.8vh;
		box-sizing: border-box;
		background: url(../../../static/images/bg1.png) no-repeat 0 0;
		background-size: cover;
		@media screen and(min-width:320px) and (max-width:767px) {
			padding-bottom: 40px;
		}
		.title{
			font-size: 30px;
			letter-spacing: 10px;
			text-align: center;
			margin-top: 60px;
			p{
				padding-left: 10px;
				box-sizing: border-box;
				&:last-child{
					padding-left: 0;
					font-size: 18px;
					letter-spacing: 0px;
				}
				
			}
			@media screen and(min-width:320px) and (max-width:767px) {
				font-size: 24px;
				letter-spacing: 5px;
				p{
					&:last-child{
						font-size: 14px;
					}
				}
			}
		}
		.item{
			display: inline-block;
			width: 50%;
			margin-top: 80px;
			img{
				width: 50%;
				display: block;
				margin: 0 auto;
				cursor: pointer;
			}
			@media screen and(min-width:320px) and (max-width:767px) {
				width: 100%;
				img{
					width: 85%;
				}
			}
		}
		&-item {
			width: 100%;
			height: 100vh;
			position: relative;
			overflow: hidden;
			display: none;
			justify-content: center;
			align-items: center;

			@media screen and(min-width:320px) and (max-width:767px) {
				display: flex;
			}

			&>img {
				height: 100%;
				position: absolute;
			}

			h2 {
				margin: 0 0;
				font-size: 26px;
				letter-spacing: 3px;
				text-align: center;
				opacity: 1;
				transform: scale(1);
			}
		}

		.advantage {
			@media screen and(min-width:320px) and (max-width:767px) {
				display: none;
			}

			&-list {
				color: #fff;
				width: 17.33%;
				height: 838px;
				display: inline-block;
				vertical-align: middle;
				transition: all .3s;

				&:nth-child(4n-0) {
					margin-right: 0px;
				}

				.cover_box {
					overflow: hidden;
					position: relative;

					img {
						width: auto;
						margin-left: -25%;
						height: 838px;
					}

					.cover_box_txt {
						position: absolute;
						width: 100%;
						top: 40%;
						padding: 0 20px;
						box-sizing: border-box;
						transition: all .3s;

						p {
							opacity: .6;
							font-size: 14px;
							text-align: center;
						}

						h3 {
							margin-top: 10px;
							font-size: 26px;
							text-align: center;
						}
						h4{
							text-align: center;
						}
					}
				}

				.innner_box {
					display: none;
					position: relative;
					overflow: hidden;

					img {
						width: auto;
						margin-left: -50%;
						height: 838px;
					}

					.innner_box_txt {
						width: 100%;
						position: absolute;

						h4 {
							font-size: 22px;
							text-align: center;
						}
					}
				}
			}

			&-active {
				width: 48%;
				height: 838px;
				cursor: pointer;

				.innner_box {
					display: block;

					.innner_box_txt {
						animation: mymove 1s;
						-webkit-animation: mymove 1s;
						opacity: 1;
						padding: 0 50px;
						box-sizing: border-box;
						top: 40%;

						.innner_box_price {
							font-size: 14px;
							text-align: left;
							font-weight: 300;
							word-wrap: break-word;
						}

						.innner_box_txt_title {
							width: 100%;
							box-sizing: border-box;
							margin-bottom: 20px;

							h4 {
								font-size: 18px;
								margin: 0 0;
							}

							a {
								color: #fff;
								text-decoration: none;
							}

							p {
								text-align: center;
								font-weight: 500;
								font-size: 14px;
							}

							h3 {
								margin-top: 10px;
								font-size: 26px;
								text-align: center;
							}
						}
					}
				}

				.cover_box {
					display: none;
				}
			}
		}
	}

	@keyframes mymove {
		from {
			opacity: 0;
			top: 0%;
		}

		to {
			opacity: 1;
			top: 40%;
		}
	}
</style>
